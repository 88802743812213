import AuthenticatedRoute from './routes/AuthenticatedRoute';
import {
  BrowserRouter as AppRouter,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { RouteList } from './index';
import Spinner from '../components/common/Spinner';
const Login = lazy(() => import('../components/Login/LoginPage'));

function Router() {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <AppRouter basename='/'>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route
            path='/'
            index
            element={
              isAuthenticated ? (
                <Navigate replace to='/leagues' />
              ) : (
                <Navigate replace to='/login' />
              )
            }
          />
          <Route
            path='/login'
            element={
              isAuthenticated ? <Navigate replace to='/leagues' /> : <Login />
            }
          />
          {RouteList.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                route.auth ? (
                  <AuthenticatedRoute redirectTo='/login'>
                    <route.element />
                  </AuthenticatedRoute>
                ) : (
                  <route.element />
                )
              }
            />
          ))}
          <Route path='*' component={Error} />
        </Routes>
      </Suspense>
    </AppRouter>
  );
}

export default Router;
