// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import rootReducer from './index'
import { createStore, applyMiddleware, compose } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: "root",
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

export { store }
