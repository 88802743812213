import { lazy } from 'react';

export default [
  {
    path: '/referee-register',
    element: lazy(() => import('../../components/Referee/Register')),
    auth: true,
  },
  {
    path: '/referee',
    element: lazy(() => import('../../components/Referee')),
    auth: true,
  },
  {
    path: '/referee/find-game',
    element: lazy(() => import('../../components/Referee/FindGame')),
    auth: true,
  },
  {
    path: '/referee/claim-game',
    element: lazy(() => import('../../components/Referee/ClaimGame')),
    auth: true,
  },
  {
    path: '/referee/match-screen',
    element: lazy(() => import('../../components/Referee/MatchScreen')),
    auth: true,
  },
  {
    path: '/score-board/live/:id',
    element: lazy(() => import('../../components/Referee/ScoreDisplay')),
    auth: false,
  },
];
