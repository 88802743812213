// ** Router Import
import React from 'react'
import Router from './router/Router'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/custom.scss'

const App = () => <Router />

export default App
