import { lazy } from 'react';

export default [
  {
    path: '/leagues',
    element: lazy(() => import('../../components/Leagues')),
    auth: true,
  },
  {
    path: '/league-details',
    element: lazy(() => import('../../components/Leagues/Details')),
    auth: true,
  },
  {
    path: '/league-join-ind',
    element: lazy(() => import('../../components/Leagues/JoinInd')),
    auth: true,
  },
  {
    path: '/league-join-team',
    element: lazy(() => import('../../components/Leagues/JoinTeam')),
    auth: true,
  },
  {
    path: '/league-confirm',
    element: lazy(() => import('../../components/Leagues/Confirm')),
    auth: true,
  },
  {
    path: '/league-summary',
    element: lazy(() => import('../../components/Leagues/Summary')),
    auth: true,
  },
  {
    path: '/fixture/details',
    element: lazy(() => import('../../components/Leagues/fixtures/index')),
    auth: true,
  },
  {
    path: '/team/:token/:league',
    element: lazy(() => import('../../components/Leagues/Invite')),
    auth: true,
  },
  {
    path: '/team/invite/:token',
    element: lazy(() => import('../../components/Leagues/PlayerInvite')),
    auth: true,
  },
];
