import { lazy } from 'react';

export default [
  {
    path: '/welcome',
    element: lazy(() => import('../../components/Dashboard/WelcomePage')),
    auth: true,
    redirect: true,
  },
  {
    path: '/home-ground',
    element: lazy(() => import('../../components/Dashboard/HomeGround')),
    auth: true,
  },
  {
    path: '/upload-picture',
    element: lazy(() => import('../../components/Dashboard/UploadPicture')),
    auth: true,
  },
  {
    path: '/personal-info',
    element: lazy(() => import('../../components/Dashboard/PersonalInfo')),
    auth: true,
  },
  {
    path: '/edit-info',
    element: lazy(() => import('../../components/Dashboard/PersonalInfo')),
    auth: true,
  },
  {
    path: '/edit-info/*',
    element: lazy(() => import('../../components/Dashboard/EditInfo')),
    auth: true,
  },
  {
    path: '/show-invite',
    element: lazy(() => import('../../components/Dashboard/ShowInvite')),
    auth: true,
  },
  {
    path: '/invite-declined',
    element: lazy(() => import('../../components/Dashboard/InviteDeclined')),
    auth: true,
  },
  {
    path: '/your-invites',
    element: lazy(() => import('../../components/Dashboard/YourInvites')),
    auth: true,
  },
];
