/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AuthenticatedRoute = ({ children, redirectTo }) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
}
export default React.memo(AuthenticatedRoute);
