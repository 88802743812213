import { combineReducers } from 'redux';

//import reducers
import { auth } from './auth/reducers'
import { booking } from './booking/reducers'

const appReducer = combineReducers({
    auth,
    booking
});
const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_STORE') {
        return appReducer(undefined, action)
    } else return appReducer(state, action)
}

export default rootReducer
